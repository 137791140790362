// start content


$twkblue: #0070A2 !default;
$twkdarkblue: #003148 !default;

$green:   #08a057 !default;
$blue:    $twkblue !default;

@import 'bootstrap/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;900&display=swap');

/* add additional CSS rules below */
html {
    scroll-behavior: smooth;
  }
  
body {
    background:#F1F1F1;
    font-size:16px;
    transition: all 200ms linear;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height:1.5;
    color: #333;
    overflow-x:hidden;
    padding-top:116px;
    
}
.serif-font {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@media (max-width: 1366px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        width:90%;
        max-width:90%;
        padding:0 25px;
    }
}

main {min-height:500px; background-color: white;}
.row {margin-left: 0px; margin-right: 0px;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .navbar-light .navbar-brand, .text-dark, .dropdown-item {
    color: #000;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight:600;
}
h4, .h4, h5, .h5 {
    line-height:1.4;
    margin-bottom: 0 !important;
}

.font-weight-bold {
    font-weight:600 !important;
}
a {
    color:inherit;
    text-decoration:none;
    transition: all .4s;
    &:hover {
        text-decoration:underline;
        color:inherit;
        transition: all .4s;
    }
}
img, video {max-width:100%;}


.item-card .categories {
    position: absolute;
    top: 10px;
    left: 30px;
    font-size:13px;
    a {
        background:#333;
        color:white;
        padding:2px 4px;
        border-radius:4px;
    }
}
small, .small {
    font-size: 90%;
}
.big {
    font-size:1.4em;
}
/* #Navigation
================================================== */
.nav-link
{
    color: white;
    &:hover {
        text-decoration:underline;
        color:inherit;
        transition: all .4s;
    }
}
.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 0 0;	
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
    background-color: $twkblue;
}
.start-header.scroll-on {	
	padding: 5px 0;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
    box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.05);
}
.start-header.scroll-on .navbar-brand img {	
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navigation-wrap {	
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
    position:fixed;
}
.social-icon {
    display: inline-block !important;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    &:hover {
        text-decoration:underline;
        color: black;
        transition: all .4s;
    }
}


/* Pages
================================================== */
@media only screen and (max-width: 1024px) {
    .page-header.big {
        font-size:3.5em;
    }
    .wrap-page-header {
        padding: 2rem 0 3rem;
    }
    .wrap-page-header {
        display:none;
    }
    .layout-default .wrap-page-header {
        display:block;
    }
    
    .socialmenu {display:none !important;}
}

@media only screen and (max-width: 1000px) {
    /*{ align-items: left !important; }*/
    .pl-3, .px-3 { padding-left: 0rem !important; }

    .dropdown-menu
    {
        background-color: transparent !important;
        color : white;
        border : 0;

        a, a:visited { color: white; }

    }

    li.dropdown-item:hover
    {
        background-color: $twkdarkblue;
    }
}

@media only screen and (max-width: 767px) {
    .page-header.big {
        font-size:2.5em;
    }

    .socialmenu {display:none !important;}

    .mr-2 { width: 50px; height: 50px; }
}
.google-auto-placed {
    margin-bottom:2rem;
} 
.comma:last-child {
    display:none;
}
.section-title h2 {
	font-weight:700;
	font-size:1.4rem;
	margin-bottom:27px;
    position: relative;
}
.section-title h2:before {
    margin-bottom: 0;
    position: absolute;
    z-index: 1;
    left: 15px;
    right: 0px;
    top: 15px;
    height: 1px;
    content: '';
    background-color: #efefef;
}

.section-title span {
    background-color: #fff;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0px 15px 0 0;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.layout-page  .section-title span {
    font-size: 2rem;
    text-transform: none;
    letter-spacing:0;
}
.layout-page .section-title h2:before {top:17px;}
.article-post ol, .article-post ul { margin-bottom:1.5rem;}
.article-post ol ol, .article-post ul ul {   
    list-style: disc;
    margin-bottom:0rem;
}
.prevnextlinks .thepostlink {
    padding: 20px 0;
    font-size: 17px;
    display: block;
        color: #111;
    font-weight: 500;
}
.prevnextlinks {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-left: 0;
    margin-right: 0;
}
.prevnextlinks .rightborder {
    border-right: 1px solid #eee;
}
.author-thumb {
    object-position: center;
    width: 50px;
    height: 50px;
    margin-right: 9px;
    border-radius: 100%;
    border: 2px solid #eaeaea;
    padding: 2px;
    object-fit: cover;
}
.post-top-meta {
	margin-bottom: 1rem;
    margin-top: 3rem;
}
.post-top-meta .author-thumb {
	width:72px;
	height:72px;
}
.post-top-meta.authorpage .author-thumb {
	margin-top:40px;
}
.post-top-meta span {
	font-size:0.9rem;
	color:rgba(0,0,0,.44);
	display:inline-block;
}
.post-top-meta .author-description {
	margin-bottom:5px;
	margin-top:10px;
	font-size:0.95rem;
}
.author-meta {
	flex:1 1 auto;
	white-space:nowrap!important;
	text-overflow:ellipsis!important;
	overflow:hidden!important;
}
span.post-name,span.post-date,span.author-meta {
	display:inline-block;
}

span.post-read-more a {
	color:rgba(0,0,0,.44);
     align-items: center;
    display: inline-block;
    float: right;
     width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
}
span.post-name a,span.post-read-more a:hover {
	color:rgba(0,0,0,.8);
}
.dot:after {
	content:"·";
	margin-left:3px;
	margin-right:3px;
}
.mainheading h1.posttitle {
	font-weight:900;
    font-family: Inter;
	//margin-bottom:2rem;
}
.link-dark {
	color:rgba(0,0,0,.8);
}
.article-post>*{
    //margin-bottom:1.5rem;
}
.article-post {
    font-size: 1em;
    min-height: 150px;
    //line-height: 1.64;
	color:rgba(0,0,0,.8);
    code {
        font-size:15px;
    }
    a {
        color:inherit; 
        box-shadow:inset 0 -2px 0 rgba(255, 229, 31,1), 0 2px 0 rgba(255, 229, 31,1);
        
    }
    a:hover {
        box-shadow:inset 0 -30px 0 rgba(255, 229, 31,1), 0 2px 0 rgba(255, 229, 31,1);
        text-decoration:none;
    }
    img, iframe {
        border-radius: 0.25rem !important;
    }
}

blockquote {
	border-left:4px solid #222;
	padding:0 0 0 20px;
	font-style:italic;
	color:rgba(0,0,0,.5);
    margin-left:30px;
    font-size: .9em;
}
.featured-image {
    display: block;
    margin-bottom: 2rem;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@media (max-width:1024px) {
	.post-top-meta .col-md-10 {
		text-align:center;
	}
}
@media (max-width:767px) {
	.post-top-meta.authorpage {
		text-align:center;
	}
}


.card {	
    border:0;
}
.card .img-thumb {
	border-top-right-radius:4px;
	border-top-left-radius:4px;
}
ul.tags {
	list-style:none;
	padding-left:0;
	margin: 0 0 1.5rem 0;
}
ul.tags li {
	display:inline-block;
	font-size:0.9rem;
}
ul.tags li a {
    color: #333;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 500;
    background:#eee;
    text-transform: capitalize;    
    box-shadow: 0 6px 10px 0 rgba(169, 169, 169, 0.1);
}
ul.tags li a:hover {
	background:rgba(0,0,0,.07);
	text-decoration:none;
}
.margtop3rem {
	margin-top: 3rem;
}
.sep {
	height:1px;
	width:20px;
	background:#999;
	margin:0px auto;
	margin-bottom:1.2rem;
}


.btn.follow {
	border-color:#ccc;
	color:#999;
	padding:3px 10px;
	text-align:center;
	border-radius:999em;
	font-size:0.85rem;
	display:inline-block;
        box-shadow: none;
    font-weight:400;
}
.btn.subscribe {
	background-color:#1C9963;
	border-color:#1C9963;
	color:rgba(255,255,255,1);
	fill:rgba(255,255,255,1);
	border-radius:30px;
	font-size:0.85rem;
	margin-left:10px;
	font-weight:600;
	text-transform:uppercase;
}
.post-top-meta .btn.follow {
	margin-left:5px;
	margin-top:-4px;
}

.form-control::-webkit-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control:-moz-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control::-moz-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control:-ms-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control::-ms-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control {border:1px solid #eee; border-radius:0;}
.authorpage h1 {
	font-weight:700;
	font-size:30px;
}
.post-top-meta.authorpage .author-thumb {
	float:none;
}
.authorpage .author-description {
	font-size:1rem;
	color:rgba(0,0,0,.6);
}
.post-top-meta.authorpage .btn.follow {
	padding:7px 20px;
	margin-top:10px;
	margin-left:0;
	font-size:0.9rem;
}
.graybg.authorpage {
	border-top:1px solid #f0f0f0;
}
.authorpostbox {
	width:760px;
	margin:0px auto;
	margin-bottom:1.5rem;
	max-width:100%;
}
.authorpostbox .img-thumb {
	width:100%;
}
.sociallinks {
	margin:1rem 0;
}
.sociallinks a {
	background:#666;
	color:#fff;
	width:22px;
	height:22px;
	display:inline-block;
	text-align:center;
	line-height:22px;
	border-radius:50%;
	font-size:12px;
}
#comments {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}
.sidebar {font-size: 15px;}
.sidebar h5 {    margin-bottom: 1rem;   text-align:center; position:relative;font-weight: 700; }
.sidebar h5 span {
background-color: #fff;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0px 15px 0 15px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.sidebar ul {list-style:none;padding-left:0;}
.sidebar ul li a {
    color: #999;
    border-bottom: 1px solid #f6f6f6;
    display: block;
    padding-bottom: 7px;
    padding-top: 7px;
}
.sidebar-section {     
    margin-bottom: 2rem;
    box-shadow:0 0px 1px 0 rgba(0, 0, 0, 0.44);
    padding: 30px;
}
.sidebar #mc_embed_signup form {
    padding: 0 !important;
}
.sidebar #mc_embed_signup .button {
    width: 100% !important;
    background: #3d61fd !important;
    height: auto;
    padding: 6px 20px;
    font-weight: 600;
}
.sidebar #mc_embed_signup h2 {
    font-weight: 400;
    font-size: 15px;
    color: #999;
    margin-bottom: 1rem; 
    line-height: 1.5;
    text-align: center;
}
.sidebar #mc_embed_signup input {width:100%;}
.sidebar #mc_embed_signup .mc-field-group {width:100% !important;}
#mc_embed_signup .mc-field-group input {text-indent: 5% !important;}
#mc_embed_signup {font-family:inherit !important;}
.sidebar-right .sidebar {max-width:inherit;}

.article-post .h1, .article-post .h2, .article-post .h3, .article-post .h4, .article-post .h5, .article-post .h6, .article-post h1, .article-post h2, .article-post h3, .article-post h4, .article-post h5, .article-post h6 {
    font-weight: 900;
    font-family: Inter;
    margin-top: 3rem;
}

img {max-width:100%;height: auto;}

.bottompagination span.navigation a {
	background: #fdd93d;
    color: #333;
    padding: 5px;
    border-radius: 3px;
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0;
    min-width: 100px;
    display: inline-block;
}
.pointerup {
	margin-bottom:-18px;
	margin-left:49%;
	font-size:30px;
}
.pointerup i.fa {
	color:#eaeaea;
}
.bottompagination span.navigation i {
	display:inline-block;
}
span.navigation {
    display: block;
    font-size: 0.93rem;
    font-weight: 700;
    text-align:center;
    position:relative;
}
span.navigation:before {
    margin-bottom: 0;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    top: 12px;
    height: 1px;
    content: '';
}
.pagination {
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 20px;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: uppercase;
}

.footer {
    background-color: $twkdarkblue;
    margin-top:4rem;
    padding:30px 0;
    ul li {
        display: inline-block;
        padding:10px;
    }
    color: white;
}

.hover-opacity:hover {
    transition: all.3s;
    opacity:.8;
}

.card-title:hover{
    text-decoration: underline;
}

.planboximage
{
    height: 250px;
    object-fit: cover;
    width: 100%
}

.postboximage
{
    height: 350px;
    overflow:hidden;
    width: 100%
}

.planboximageoverlay {
    position: absolute;
    height: 100%;
    width: 100%; 
    background: rgba(0,0,0,0.15);
    &:hover{
        background: rgba(0,0,0,0.35);
        transition-duration: .4s;
    }
}

.postboximageoverlay {
    position: absolute;
    height: 100%;
    width: 100%; 
    background: rgba(0,0,0,0.35);
    &:hover{
        background: rgba(0,0,0,0.65);
        transition-duration: .4s;
    }
}

.postinternalblock
{
  font-family: sans-serif;
  position:absolute;
  bottom:0;
  color: white;
  margin-top: 5px;
  padding-left: 10px;
  padding-right : 10px;
  h2 { color:white; font-family: sans-serif; text-transform: uppercase; font-size: 1.1em; }
}

.cardmax { 
    width : 100%;
    height: 100px;
    clear:both;
 }

.col-lg-4
{
    padding-left:  5px;
    padding-right: 5px;
}

.section-nav {
    background-color: #fff;
    margin: 5px 0;
    padding: 10px 30px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
  }

h1.frontpage {
    font-weight: 900;
    font-family: Inter;
    text-transform: uppercase;
}

.featured-posts { margin-bottom:50px;}
.post-date { text-transform: uppercase;}
h2.card-title { font-size: 1.5em; font-family: Inter; font-weight: 900; }


.ribbon 
{
    $default-right: 10px;
    $default-top: -3px;
    $default-color: $twkblue;
    $default-height: 36px;
    
    position: absolute;
    left: 20px;
    top: var(--top, $default-top);
    
    filter: drop-shadow(2px 3px 2px rgba(black, 0.25));
    
    > .content 
    {
        color: white;
        font-size: 1.25rem;
        text-align: center;
        font-weight: 400;
        background: var(--color, $twkblue);
        padding: 8px 5px 5px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
        // width: var(--width, $default-width);
        min-height: var(--height, $default-height);
        h5 { color: white; }
    }

    > .free {
        background-color:  rgb(55, 58, 58);
    }

    &.down > .content {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
      }
}

a, figure {
    display: inline-block;
}
figcaption {
    margin: 10px 0 0 0;
    font-variant: small-caps;
    font-family: Arial;
    font-weight: bold;
    color: #bb3333;
}
figure {
    margin-left: 150px;
    border : 1px solid #cacaca;
    padding: 5px;
    img:hover {
        transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
    }
    img {
        transition: transform 0.2s;
        -webkit-transition: -webkit-transform 0.2s;
        -moz-transition: -moz-transform 0.2s;
        -o-transition: -o-transform 0.2s;
    }
    
}

@import "fruity.css"